export default (params: Record<string, any>) => ({
  home: { name: 'Home', path: { name: 'index' } },

  posts: {
    name: 'For sale',
    path: params.path,
    parent: 'home',
  },

  category: {
    name: params?.category?.value?.name,
    path: params?.post
      ? {
          name: 'category',
          params: params.path,
        }
      : params.path,
    parent: 'home',
  },

  'category.post': {
    name: params?.post?.value?.title,
    path: {
      name: 'category-for-sale-slug',
      params: {
        category: params?.post?.value?.category?.slug,
        slug: params?.post?.value?.slug,
      },
    },
    parent: 'category',
  },

  'search-results': {
    name: 'Search results',
    path: params.path,
    parent: 'home',
  },

  dashboard: {
    name: 'My Haystack',
    path: { name: 'dashboard' },
    parent: 'home',
  },

  'my-profile': {
    name: 'My profile',
    path: { name: 'dashboard-my-profile' },
    parent: 'dashboard',
  },

  'my-adverts': {
    name: 'My adverts',
    path: { name: 'dashboard-my-adverts' },
    parent: 'dashboard',
  },

  'my-adverts.new': {
    name: `Place an advert`,
    path: {
      name: 'dashboard-my-adverts-new',
    },
    parent: 'my-adverts',
  },

  'my-adverts.edit': {
    name: `Edit advert`,
    path: {
      name: 'dashboard-my-adverts-uuid-edit',
      params: { uuid: params?.post?.value?.uuid },
    },
    parent: 'my-adverts',
  },

  'my-adverts.preview': {
    name: `Preview advert`,
    path: {
      name: 'dashboard-my-adverts-uuid-preview',
      params: { uuid: params?.post?.value?.uuid },
    },
    parent: 'my-adverts',
  },

  'my-messages': {
    name: 'My messages',
    path: { name: 'dashboard-my-messages' },
    parent: 'dashboard',
  },

  'my-messages.show': {
    name: `View message`,
    path: {
      name: 'dashboard-my-messages-uuid',
      params: { uuid: params?.thread?.value?.uuid },
    },
    parent: 'my-adverts',
  },

  'my-favourites': {
    name: 'My favourites',
    path: { name: 'dashboard-my-favourites' },
    parent: 'dashboard',
  },

  'payment-history': {
    name: 'Payment history',
    path: { name: 'dashboard-payment-history' },
    parent: 'dashboard',
  },

  'save-searches': {
    name: 'Saved searches',
    path: { name: 'dashboard-saved-searches' },
    parent: 'dashboard',
  },

  'download-app': {
    name: 'Download app',
    path: { name: 'app' },
    parent: 'home',
  },

  'animal-policy': {
    name: 'Animal Policy',
    path: { name: 'animal-policy' },
    parent: 'home',
  },

  'cookie-policy': {
    name: 'Cookie policy',
    path: { name: 'cookie-policy' },
    parent: 'home',
  },

  'dealer-enquiries': {
    name: 'Dealer Enquiries',
    path: { name: 'dealer-enquiries' },
    parent: 'home',
  },

  'how-to-sell': {
    name: 'How to sell',
    path: { name: 'how-to-sell' },
    parent: 'home',
  },

  'privacy-policy': {
    name: 'Privacy policy',
    path: { name: 'privacy-policy' },
    parent: 'home',
  },

  'terms-and-conditions': {
    name: 'Terms and conditions',
    path: { name: 'terms-and-conditions' },
    parent: 'home',
  },

  'account-deleted': {
    name: 'Account Deleted',
    path: { name: 'account-deleted' },
    parent: 'home',
  },

  'account-deletion-failed': {
    name: 'Account Deletion Failed',
    path: { name: 'account-deletion-failed' },
    parent: 'home',
  },
});
