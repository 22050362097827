<script setup lang="ts">
interface Crumb {
  name: string;
  path: { name: string };
}

const props = defineProps<{
  crumbs: Array<Crumb>;
}>();

const isLeaving = ref(false);

onBeforeRouteLeave(() => {
  isLeaving.value = true;
});

const { largerThan } = useScreen();

const truncate = (string: string, length: number) => {
  if (typeof string !== 'string' || typeof length !== 'number' || length <= 0) {
    return;
  }

  if (largerThan('sm')) {
    return string;
  }

  let result = '';
  let i = 0;

  while (i < length && i < string.length) {
    result += string[i];
    i++;

    if (i === length && string[i - 1] === ' ') {
      while (i < string.length && string[i] === ' ') {
        i++;
      }
    }
  }

  result += '...';

  return result;
};
</script>

<template>
  <div v-if="crumbs && !isLeaving" class="the-breadcrumb">
    <div class="the-breadcrumb__inner">
      <ul>
        <li
          v-for="(crumb, index) in crumbs"
          :key="index"
          class="the-breadcrumb__crumb"
        >
          <NuxtLink v-if="crumb.path" :to="crumb.path">
            {{ truncate(crumb.name, 15) }}
          </NuxtLink>
          <span v-else>
            {{ crumb.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.the-breadcrumb {
  background: color(neutral, 200);

  position: relative;
  &__inner {
    @include container;
    ul {
      margin: 0;
      padding: 0;

      display: flex;

      list-style-type: none;

      align-items: center;
    }
  }
  &__crumb {
    text-transform: uppercase;

    height: 40px;

    display: flex;

    font-size: 10px;

    align-items: center;
    a {
      letter-spacing: 0.03em;
      text-decoration: none;

      color: color(neutral, 800);

      padding: 14px 0;
    }
    & + & {
      margin-left: 10px;
      &:before {
        content: '';

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 36'%3E%3Cpath fill='%234F645D' d='M17.515 16.712a1.953 1.953 0 0 1 0 2.577L3.492 35.316C2.287 36.694 0 35.851 0 34.028V1.973C0 .15 2.287-.694 3.492.684l14.023 16.028Z'/%3E%3C/svg%3E");

        height: 10px;
        margin-right: 10px;
        width: 5px;
      }
    }
    &:last-child {
      a {
        color: color(neutral, 900);
      }
    }
  }
}
</style>
