import breadcrumbs from '@/config/breadcrumbs';

export const useBreadcrumb = (page: string, params = {}) => {
  const breadcrumb = [];

  const processCrumb = (crumb) => {
    const { parent, ...rest } = crumb;

    breadcrumb.push(rest);

    if (crumb.parent) {
      processCrumb(breadcrumbs(params)[crumb.parent]);
    }
  };

  if (!breadcrumbs(params)[page]) {
    throw new Error(`Breadcrumb ${page} not found`);
  }

  processCrumb(breadcrumbs(params)[page]);

  return breadcrumb.reverse();
};
